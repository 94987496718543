export const environment = {
    environment: 'prod',
    production: true,
    endpoints: {
        base: '*.enganchesaragon.com/api/*',
        microservices: {
            appChecker: 'https://appcheckerservice.enganchesaragon.com/api',
            complianceReport: 'https://compliancereportservice.enganchesaragon.com/api',
            mail: 'https://mailservice.enganchesaragon.com/api',
            ocr: 'https://ocrservice.enganchesaragon.com/api',
            order: 'https://orderservice.enganchesaragon.com/api',
            product: 'https://productservice.enganchesaragon.com/api',
            signature: 'https://signatureservice.enganchesaragon.com/api',
            storage: 'https://storageservice.enganchesaragon.com/api',
            user: 'https://userservice.enganchesaragon.com/api',
            log: 'https://logservice.enganchesaragon.com/api',
            aragonMaster: 'https://aragonmasterservice.enganchesaragon.com/api',
            customer: 'https://customerservice.enganchesaragon.com/api',
            ecommerce: 'https://ecommerceservice.enganchesaragon.com/api',
            scheduler: 'https://schedulerservice.enganchesaragon.com/api',
            aragonVideo: 'https://aragonvideoservice.enganchesaragon.com/api',
            payment: 'https://paymentservice.enganchesaragon.com/api',
            planning: '',
            technicalReport: '',
        },
    },
    unprotectedEndpoints: [
        'https://productservice.enganchesaragon.com/api/Languages',
        'https://productservice.enganchesaragon.com/api/Brands',
        'https://productservice.enganchesaragon.com/api/Models',
        'https://productservice.enganchesaragon.com/api/Bodies',
        'https://productservice.enganchesaragon.com/api/SpecVariantModelBodies',
        'https://productservice.enganchesaragon.com/api/SpecVariantModelBodyTranslations',
        'https://storageservice.enganchesaragon.com/api/Blobs/Stream',
        'https://storageservice.enganchesaragon.com/api/Catalogues/Documents',
        'https://storageservice.enganchesaragon.com/api/Catalogues/Videos',
        'https://storageservice.enganchesaragon.com/api/Catalogues/Photos',
        'https://productservice.enganchesaragon.com/api/Products',
        'https://productservice.enganchesaragon.com/api/ModelKitSpecs',
        'https://productservice.enganchesaragon.com/api/ModelHitchSpecs',
        'https://ecommerceservice.enganchesaragon.com/api/Categories',
        'https://ecommerceservice.enganchesaragon.com/api/Tickets',
        'https://ecommerceservice.enganchesaragon.com/api/Products/Tickets',
        'https://ecommerceservice.enganchesaragon.com/api/ProductDescriptions',
        'https://ecommerceservice.enganchesaragon.com/api/ProductImages',
        'https://ecommerceservice.enganchesaragon.com/api/ProductVideos',
        'https://ecommerceservice.enganchesaragon.com/api/SalesPrices',
        'https://ecommerceservice.enganchesaragon.com/api/Products',
        'https://ecommerceservice.enganchesaragon.com/api/Distributors',
        'https://ecommerceservice.enganchesaragon.com/api/Urls',
        'https://ecommerceservice.enganchesaragon.com/api/HighlightedProducts',
        'https://ecommerceservice.enganchesaragon.com/api/Faqs',
        'https://ecommerceservice.enganchesaragon.com/api/NewsArticles',
        'https://ecommerceservice.enganchesaragon.com/api/ProductColors',
        'https://dev-ecommerceservice.enganchesaragon.com/api/Products/CategoryHasVents',
        'https://ecommerceservice.enganchesaragon.com/api/ProductCategories',
        'https://customerservice.enganchesaragon.com/api/Customers/Particular',
        'https://customerservice.enganchesaragon.com/api/Customers/Professional',
        'https://ecommerceservice.enganchesaragon.com/api/ShippingCosts',
        'https://ecommerceservice.enganchesaragon.com/api/CatalogueSections',
        'https://mailservice.enganchesaragon.com/api/Mails/Contact',
        'https://customerservice.enganchesaragon.com/api/Addresses/GetCountyListByCountry',
        'https://customerservice.enganchesaragon.com/api/Addresses/GetPostCodeListByCounty',
        'https://customerservice.enganchesaragon.com/api/Addresses/GetPostCodeAddress',
        'https://paymentservice.enganchesaragon.com/api/TPVPayments',
        'https://compliancereportservice.enganchesaragon.com/api/ComplianceReports/GetByNumber',
        'https://mailservice.enganchesaragon.com/api/Mails/WorkWithUs',
    ],
    assets: {
        blobStorage: 'https://storage.enganchesaragon.com',
        b2c: {
            resetPasswordUrl:
                'https://engaragonb2c.b2clogin.com/engaragonb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ResetPasswordUF',
            resetPasswordTestUrl:
                'https://engaragonb2c.b2clogin.com/engaragonb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ResetPasswordTestUF',
        },
        googleMaps: {
            key: 'AIzaSyDD6Grq_Nj-qUeU2lgj7M5R7JDQMeyi5Cc',
        },
        admin: {
            url: 'https://adminweb.enganchesaragon.com',
        },
        ecommerce: {
            url: '.enganchesaragon.com',
        },
    },
    i18nUrl: '/assets/i18n/',
    rollbarAccessToken: '693df11119e14d568918fa6706f92684',
    googleTagManagerId: 'GTM-NS66T9X',
    redsysUrl: 'https://sis.redsys.es/sis/realizarPago',
    originToken: '0fYWKrDQOryZ6XLZwhmkPGSCV7PMQPVubBUygYHriZ1jxeuDbxyQ8jzyEzCSUd0xMZ3APcOKlVN94iKfscMinP5ypplk1QfFe4gqZYMLPh58ahdW6AOlBSue9tc04VDg8oTnPCSy0fCKKFdnv2BDuIisbrTN1k1qDQKmGzmcPrkx8Lq6nPVY5Cy8N5KFurxICPzUKr5UJekc5d6qGygg8JHb2mJPRU2NplXofA6Xm0iNqOSqJ7unyHrl2roYlxO6',
    cveEmail: 'info@enganchesaragon.com',
};

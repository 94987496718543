import { APP_INITIALIZER, LOCALE_ID, NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AragonVideoServiceVideoRepository,
    AragonVideoServiceVideoWebRepository,
    CombinedRepoVehicleRepository,
    CombinedRepoVehicleWebRepository,
    ComplianceReportServiceComplianceReportRepository,
    ComplianceReportServiceComplianceReportWebRepository,
    CustomerServiceAddressesRepository,
    CustomerServiceAddressesWebRepository,
    CustomerServiceCustomerRepository,
    CustomerServiceCustomerUpdateRequestValidationRepository,
    CustomerServiceCustomerUpdateRequestValidationWebRepository,
    CustomerServiceCustomerWebRepository,
    CustomerServiceVATPercentagesRepository,
    CustomerServiceVATPercentagesWebRepository,
    EcommerceServiceBudgetRepository,
    EcommerceServiceBudgetWebRepository,
    EcommerceServiceCatalogueProductsRepository,
    EcommerceServiceCatalogueProductsWebRepository,
    EcommerceServiceCatalogueSectionsRepository,
    EcommerceServiceCatalogueSectionsWebRepository,
    EcommerceServiceCategoryRepository,
    EcommerceServiceCategoryWebRepository,
    EcommerceServiceCustomerBudgetDiscountsRepository,
    EcommerceServiceCustomerBudgetDiscountsWebRepository,
    EcommerceServiceDistributorsRepository,
    EcommerceServiceDistributorsWebRepository,
    EcommerceServiceFaqsRepository,
    EcommerceServiceFaqsWebRepository,
    EcommerceServiceHighlightedProductRepository,
    EcommerceServiceHighlightedProductWebRepository,
    EcommerceServiceNewsArticleRepository,
    EcommerceServiceNewsArticleWebRepository,
    EcommerceServiceProductCategoriesRepository,
    EcommerceServiceProductCategoriesWebRepository,
    EcommerceServiceProductColorsRepository,
    EcommerceServiceProductColorsWebRepository,
    EcommerceServiceProductDescriptionsRepository,
    EcommerceServiceProductDescriptionsWebRepository,
    EcommerceServiceProductImagesRepository,
    EcommerceServiceProductImagesWebRepository,
    EcommerceServiceProductRepository,
    EcommerceServiceProductVideosRepository,
    EcommerceServiceProductVideosWebRepository,
    EcommerceServiceProductWebRepository,
    EcommerceServiceSalesLineDiscountsRepository,
    EcommerceServiceSalesLineDiscountWebRepository,
    EcommerceServiceSalesPricesRepository,
    EcommerceServiceSalesPricesWebRepository,
    EcommerceServiceShippingCostRepository,
    EcommerceServiceShippingCostWebRepository,
    EcommerceServiceShoppingCartLinesRepository,
    EcommerceServiceShoppingCartLinesWebRepository,
    EcommerceServiceShoppingCartsRepository,
    EcommerceServiceShoppingCartsWebRepository,
    EcommerceServiceTicketsRepository,
    EcommerceServiceTicketsWebRepository,
    EcommerceServiceUrlsRepository,
    EcommerceServiceUrlsWebRepository,
    EnganchesaragonAdminLibModule,
    LanguagesLibService,
    MailServiceMailRepository,
    MailServiceMailWebRepository,
    OrderServiceDeliveryNotesRepository,
    OrderServiceDeliveryNotesWebRepository,
    OrderServiceSalesHeaderRepository,
    OrderServiceSalesHeaderWebRepository,
    OrderServiceSalesInvoiceHeaderRepository,
    OrderServiceSalesInvoiceHeaderWebRepository,
    PaymentServicePaypalCheckoutRepository,
    PaymentServicePaypalCheckoutWebRepository,
    PaymentServiceProfessionalCheckoutRepository,
    PaymentServiceProfessionalCheckoutWebRepository,
    PaymentServiceRedsysCheckoutRepository,
    PaymentServiceRedsysCheckoutWebRepository,
    PaymentServiceTpvPaymentsRepository,
    PaymentServiceTpvPaymentsWebRepository,
    ProductServiceBodyRepository,
    ProductServiceBodyWebRepository,
    ProductServiceBrandRepository,
    ProductServiceBrandWebRepository,
    ProductServiceLanguageModelVehicleRepository,
    ProductServiceLanguageModelVehicleWebRepository,
    ProductServiceLanguageRepository,
    ProductServiceLanguageWebRepository,
    ProductServiceModelHitchSpecsRepository,
    ProductServiceModelHitchSpecsWebRepository,
    ProductServiceModelKitSpecsRepository,
    ProductServiceModelKitSpecsWebRepository,
    ProductServiceModelRepository,
    ProductServiceModelWebRepository,
    ProductServiceProductRepository,
    ProductServiceProductWebRepository,
    ProductServiceResourceTranslationRepository,
    ProductServiceResourceTranslationWebRepository,
    ProductServiceSpecificKitRepository,
    ProductServiceSpecificKitWebRepository,
    ProductServiceSpecVariantModelBodyRepository,
    ProductServiceSpecVariantModelBodyTranslationRepository,
    ProductServiceSpecVariantModelBodyTranslationWebRepository,
    ProductServiceSpecVariantModelBodyWebRepository,
    ProductServiceVariantBodyRepository,
    ProductServiceVariantBodyWebRepository,
    ProductServiceVariantRepository,
    ProductServiceVariantWebRepository,
    ProductServiceWestfaliaProductRepository,
    ProductServiceWestfaliaProductWebRepository,
    SchedulerServiceJobRepository,
    SchedulerServiceJobWebRepository,
    SchedulerServiceOrderRepository,
    SchedulerServiceOrderWebRepository,
    ShippingAgentsServiceCustomerRepository,
    ShippingAgentsServiceCustomerWebRepository,
    ShipToAddressesServiceCustomerRepository,
    ShipToAddressesServiceCustomerWebRepository,
    SignatureServiceSignRequestRepository,
    SignatureServiceSignRequestWebRepository,
    StorageServiceBlobRepository,
    StorageServiceBlobWebRepository,
    StorageServiceCatalogueRepository,
    StorageServiceCatalogueWebRepository,
    StorageServiceUploadFtpRepository,
    StorageServiceUploadFtpWebRepository,
    UserServiceUserRepository,
    UserServiceUserWebRepository,
    VideosRepository,
    VideosWebRepository,
} from '@socialonce/enganchesaragon-adminnav-lib';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { DialogModule, DialogService } from '@ngneat/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { AppInitService } from './shared/services/app-init.service';
import { CustomTranslateLoader } from './shared/translate.loader';
import { MyMissingTranslationHandler } from './shared/missing-translation-handler';
import { AuthInterceptor } from './shared/auth/interceptors/auth.interceptor';
import { AuthHeaderForUnprotectedEndpointsWhenLoggedInInterceptor } from './shared/auth/interceptors/auth-header-for-unprotected-endpoints-when-logged-in.interceptor';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function playerFactory() {
    return import('lottie-web');
}

export function msalUnprotectedRoutes(): Map<string, Array<string> | null> {
    const routes = new Map<string, Array<string> | null>([]);

    environment.unprotectedEndpoints.forEach(endpoint => {
        routes.set(endpoint, null);
    });

    routes.set(environment.endpoints.base, [
        'https://engaragonb2c.onmicrosoft.com/microservices-api/user_impersonation',
    ]);
    return routes;
}

export function initializeApp(appInitService: AppInitService) {
    return (): Promise<unknown> => {
        return appInitService.init();
    };
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
        domain: `${environment.assets.ecommerce.url}`,
    },
    palette: {
        popup: {
            background: '#FFF',
        },
        button: {
            background: '#EA580C',
        },
    },
    theme: 'edgeless',
    type: 'opt-out',
    position: 'bottom',
    layout: 'enganches-layout',
    layouts: {
        'enganches-layout':
            '<div class="my-cool-layout" style="background-color: white;">\
                <div style="padding: 20px; border-left: 5px solid #EA580C; border-right: 5px solid #EA580C; border-top: 5px solid #EA580C;">\
                <h1 class="cc-header">{{header}}</h1>\
                {{message}}\
                </div>\
                <div style="display: flex; flex-direction: row;">\
                    <div style="flex: 1">{{allow}}</div>\
                    <div style="flex: 1">{{deny}}</div>\
                    <div style="flex: 1">{{link}}</div>\
                </div>\
            </div>',
    },
    elements: {
        header: 'INFORMACIÓN BÁSICA SOBRE COOKIES',
        message:
            '<p style="text-align: justify;">Bienvenida/o a la información básica sobre las cookies de la página web responsabilidad de la entidad:</p>\
        <br />\
        <p style="text-align: justify;">ENGANCHES Y REMOLQUES ARAGON S.L.</p>\
        <br />\
        <p style="text-align: justify;">Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, “smartphone” o tableta cada vez que visitas nuestra página web. Algunas cookies son nuestras y otras pertenecen a empresas externas que prestan servicios para nuestra página web.</p>\
        <br />\
        <p style="text-align: justify;">Las cookies pueden ser de varios tipos: las cookies técnicas son necesarias para que nuestra página web pueda funcionar, no necesitan de tu autorización y son las únicas que tenemos activadas por defecto.</p>\
        <br />\
        <p style="text-align: justify;">El resto de cookies sirven para mejorar nuestra página, para personalizarla en base a tus preferencias, o para poder mostrarte publicidad ajustada a tus búsquedas, gustos e intereses personales. Puedes aceptar todas estas cookies pulsando el botón <b>ACEPTAR</b>, rechazarlas pulsando el botón <b>RECHAZAR</b> o configurarlas clicando en el apartado <b>CONFIGURACIÓN DE COOKIES</b></p>\
        <br />\
        <p style="text-align: justify;">Si quieres más información, consulta la <b><a href="es/politica-cookies" style="text-decoration: none">POLÍTICA DE COOKIES</a></b> de nuestra página web.</p>',
        allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">ACEPTAR</a>',
        deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">RECHAZAR</a>',
        link: '<a style="text-decoration: none" aria-label="configurar cookies" tabindex="0" class="cc-btn cc-link" href="es/politica-cookies/configuracion">CONFIGURACIÓN DE COOKIES</a>',
    },
    content: {
        policy: 'Política de Cookies',
    },
    container: document.getElementById('cookie-container') ?? document.body,
    // autoOpen: false,
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        DialogModule.forRoot(),
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useFactory: httpTranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
            },
            defaultLanguage: 'es',
        }),
        EnganchesaragonAdminLibModule.forRoot(environment),
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: '7d679aaf-57bb-4aee-a166-f44fb59ceb23', // This is your client ID
                    authority:
                        'https://engaragonb2c.b2clogin.com/tfp/engaragonb2c.onmicrosoft.com/b2c_1_registeruf', // Tenant ID
                    // 'https://engaragonb2c.b2clogin.com/tfp/engaragonb2c.onmicrosoft.com/b2c_1_registertestuf', // Test
                    redirectUri: '/auth', // This is your redirect URI
                    postLogoutRedirectUri: '/',
                    // validateAuthority: true, // Crashes in 2.0
                    knownAuthorities: ['engaragonb2c.b2clogin.com'],
                    // this solves error at first auth. Check if it's necessary and if so, do a workaround:
                    // navigateToLoginRequestUrl: false
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE,
                },
                // system: {
                //     logger: new Logger(loggerCallback, {
                //         correlationId: '1234',
                //         level: LogLevel.Error,
                //         piiLoggingEnabled: true
                //     })
                // }
            }),
            {
                interactionType: InteractionType.Redirect, // MSAL Guard Configuration
                authRequest: {
                    scopes: [
                        'https://engaragonb2c.onmicrosoft.com/microservices-api/user_impersonation',
                    ],
                },
                loginFailedRoute: '/',
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: msalUnprotectedRoutes(),
                // protectedResourceMap: new Map<string, Array<string> | null>([
                //     [environment.endpoints.base, ['https://engaragonb2c.onmicrosoft.com/microservices-api/user_impersonation']],
                //     ['*.social11pruebas.eu', ['https://engaragonb2c.onmicrosoft.com/microservices-api/user_impersonation']]
                // ]),
            }
        ),
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        DialogService,
        CookieService,
        AppInitService,
        {
            provide: LOCALE_ID,
            useValue: 'es',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHeaderForUnprotectedEndpointsWhenLoggedInInterceptor,
            multi: true,
        },
        {
            provide: LanguagesLibService,
            useClass: LanguagesLibService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [AppInitService],
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.googleTagManagerId,
        },
        {
            provide: ProductServiceBrandRepository,
            useClass: ProductServiceBrandWebRepository,
        },
        {
            provide: ProductServiceProductRepository,
            useClass: ProductServiceProductWebRepository,
        },
        {
            provide: StorageServiceBlobRepository,
            useClass: StorageServiceBlobWebRepository,
        },
        {
            provide: StorageServiceCatalogueRepository,
            useClass: StorageServiceCatalogueWebRepository,
        },
        {
            provide: ProductServiceModelRepository,
            useClass: ProductServiceModelWebRepository,
        },
        {
            provide: ProductServiceBodyRepository,
            useClass: ProductServiceBodyWebRepository,
        },
        {
            provide: CombinedRepoVehicleRepository,
            useClass: CombinedRepoVehicleWebRepository,
        },
        {
            provide: ProductServiceVariantRepository,
            useClass: ProductServiceVariantWebRepository,
        },
        {
            provide: ProductServiceVariantBodyRepository,
            useClass: ProductServiceVariantBodyWebRepository,
        },
        {
            provide: ProductServiceLanguageRepository,
            useClass: ProductServiceLanguageWebRepository,
        },
        {
            provide: ProductServiceLanguageModelVehicleRepository,
            useClass: ProductServiceLanguageModelVehicleWebRepository,
        },
        {
            provide: ProductServiceSpecificKitRepository,
            useClass: ProductServiceSpecificKitWebRepository,
        },
        {
            provide: ProductServiceResourceTranslationRepository,
            useClass: ProductServiceResourceTranslationWebRepository,
        },
        {
            provide: ProductServiceSpecVariantModelBodyTranslationRepository,
            useClass: ProductServiceSpecVariantModelBodyTranslationWebRepository,
        },
        {
            provide: ProductServiceSpecVariantModelBodyRepository,
            useClass: ProductServiceSpecVariantModelBodyWebRepository,
        },
        {
            provide: ComplianceReportServiceComplianceReportRepository,
            useClass: ComplianceReportServiceComplianceReportWebRepository,
        },
        {
            provide: UserServiceUserRepository,
            useClass: UserServiceUserWebRepository,
        },
        {
            provide: SignatureServiceSignRequestRepository,
            useClass: SignatureServiceSignRequestWebRepository,
        },
        {
            provide: EcommerceServiceShoppingCartsRepository,
            useClass: EcommerceServiceShoppingCartsWebRepository,
        },
        {
            provide: EcommerceServiceShoppingCartLinesRepository,
            useClass: EcommerceServiceShoppingCartLinesWebRepository,
        },
        {
            provide: EcommerceServiceDistributorsRepository,
            useClass: EcommerceServiceDistributorsWebRepository,
        },
        {
            provide: EcommerceServiceCategoryRepository,
            useClass: EcommerceServiceCategoryWebRepository,
        },
        {
            provide: EcommerceServiceTicketsRepository,
            useClass: EcommerceServiceTicketsWebRepository,
        },
        {
            provide: EcommerceServiceProductDescriptionsRepository,
            useClass: EcommerceServiceProductDescriptionsWebRepository,
        },
        {
            provide: EcommerceServiceProductImagesRepository,
            useClass: EcommerceServiceProductImagesWebRepository,
        },
        {
            provide: EcommerceServiceSalesPricesRepository,
            useClass: EcommerceServiceSalesPricesWebRepository,
        },
        {
            provide: EcommerceServiceProductVideosRepository,
            useClass: EcommerceServiceProductVideosWebRepository,
        },
        {
            provide: EcommerceServiceProductRepository,
            useClass: EcommerceServiceProductWebRepository,
        },
        {
            provide: CustomerServiceCustomerRepository,
            useClass: CustomerServiceCustomerWebRepository,
        },
        {
            provide: OrderServiceSalesHeaderRepository,
            useClass: OrderServiceSalesHeaderWebRepository,
        },
        {
            provide: EcommerceServiceUrlsRepository,
            useClass: EcommerceServiceUrlsWebRepository,
        },
        {
            provide: ProductServiceModelHitchSpecsRepository,
            useClass: ProductServiceModelHitchSpecsWebRepository,
        },
        {
            provide: ProductServiceModelKitSpecsRepository,
            useClass: ProductServiceModelKitSpecsWebRepository,
        },
        {
            provide: ShipToAddressesServiceCustomerRepository,
            useClass: ShipToAddressesServiceCustomerWebRepository,
        },
        {
            provide: EcommerceServiceHighlightedProductRepository,
            useClass: EcommerceServiceHighlightedProductWebRepository,
        },
        {
            provide: EcommerceServiceFaqsRepository,
            useClass: EcommerceServiceFaqsWebRepository,
        },
        {
            provide: EcommerceServiceSalesLineDiscountsRepository,
            useClass: EcommerceServiceSalesLineDiscountWebRepository,
        },
        {
            provide: EcommerceServiceNewsArticleRepository,
            useClass: EcommerceServiceNewsArticleWebRepository,
        },
        {
            provide: PaymentServicePaypalCheckoutRepository,
            useClass: PaymentServicePaypalCheckoutWebRepository,
        },
        {
            provide: PaymentServiceProfessionalCheckoutRepository,
            useClass: PaymentServiceProfessionalCheckoutWebRepository,
        },
        {
            provide: EcommerceServiceProductColorsRepository,
            useClass: EcommerceServiceProductColorsWebRepository,
        },
        {
            provide: EcommerceServiceProductCategoriesRepository,
            useClass: EcommerceServiceProductCategoriesWebRepository,
        },
        {
            provide: PaymentServiceRedsysCheckoutRepository,
            useClass: PaymentServiceRedsysCheckoutWebRepository,
        },
        {
            provide: EcommerceServiceShippingCostRepository,
            useClass: EcommerceServiceShippingCostWebRepository,
        },
        {
            provide: EcommerceServiceCatalogueSectionsRepository,
            useClass: EcommerceServiceCatalogueSectionsWebRepository,
        },
        {
            provide: EcommerceServiceBudgetRepository,
            useClass: EcommerceServiceBudgetWebRepository,
        },
        {
            provide: CustomerServiceCustomerUpdateRequestValidationRepository,
            useClass: CustomerServiceCustomerUpdateRequestValidationWebRepository,
        },
        {
            provide: MailServiceMailRepository,
            useClass: MailServiceMailWebRepository,
        },
        {
            provide: OrderServiceSalesInvoiceHeaderRepository,
            useClass: OrderServiceSalesInvoiceHeaderWebRepository,
        },
        {
            provide: ShippingAgentsServiceCustomerRepository,
            useClass: ShippingAgentsServiceCustomerWebRepository,
        },
        {
            provide: CustomerServiceVATPercentagesRepository,
            useClass: CustomerServiceVATPercentagesWebRepository,
        },
        {
            provide: CustomerServiceAddressesRepository,
            useClass: CustomerServiceAddressesWebRepository,
        },
        {
            provide: StorageServiceUploadFtpRepository,
            useClass: StorageServiceUploadFtpWebRepository,
        },
        {
            provide: PaymentServiceTpvPaymentsRepository,
            useClass: PaymentServiceTpvPaymentsWebRepository,
        },
        {
            provide: ProductServiceWestfaliaProductRepository,
            useClass: ProductServiceWestfaliaProductWebRepository,
        },
        {
            provide: EcommerceServiceCatalogueProductsRepository,
            useClass: EcommerceServiceCatalogueProductsWebRepository,
        },
        {
            provide: SchedulerServiceOrderRepository,
            useClass: SchedulerServiceOrderWebRepository,
        },
        {
            provide: SchedulerServiceJobRepository,
            useClass: SchedulerServiceJobWebRepository,
        },
        {
            provide: EcommerceServiceCustomerBudgetDiscountsRepository,
            useClass: EcommerceServiceCustomerBudgetDiscountsWebRepository,
        },
        {
            provide: VideosRepository,
            useClass: VideosWebRepository,
        },
        {
            provide: AragonVideoServiceVideoRepository,
            useClass: AragonVideoServiceVideoWebRepository,
        },
        {
            provide: OrderServiceDeliveryNotesRepository,
            useClass: OrderServiceDeliveryNotesWebRepository,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, environment.i18nUrl);
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    EcommerceServiceShippingCostGetRequestUIModel,
    EcommerceServiceShippingCostRepository,
    EcommerceServiceShoppingCartLinesCreateRequestUIModel,
    EcommerceServiceShoppingCartLinesRepository,
    EcommerceServiceShoppingCartsGetResponseUIModel,
    EcommerceServiceShoppingCartsRepository,
    ErrorUIModel,
} from '@socialonce/enganchesaragon-adminnav-lib';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastsService } from '../toasts/toast.service';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartsService {
    counterShoppingCart$ = new ReplaySubject<number>();

    constructor(
        private shoppingCartsRepository: EcommerceServiceShoppingCartsRepository,
        private toastsService: ToastsService,
        private translateService: TranslateService,
        private shoppingCartLinesRepository: EcommerceServiceShoppingCartLinesRepository,
        private shippingCostRepository: EcommerceServiceShippingCostRepository
    ) {}

    get(): Observable<EcommerceServiceShoppingCartsGetResponseUIModel> {
        return this.shoppingCartsRepository.get().pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ShoppingCart'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    createLine(request: EcommerceServiceShoppingCartLinesCreateRequestUIModel): Observable<number> {
        return this.shoppingCartLinesRepository.create(request).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg:
                        err.type && err.type === 'QE_WARN'
                            ? err.detail
                            : this.translateService.instant('Errors.ShoppingCartLineCreate'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    createLineFromBudget(
        request: EcommerceServiceShoppingCartLinesCreateRequestUIModel
    ): Observable<number> {
        return this.shoppingCartLinesRepository.createFromBudget(request).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg:
                        err.type && err.type === 'QE_WARN'
                            ? err.detail
                            : this.translateService.instant('Errors.ShoppingCartLineCreate'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    deleteLine(id: number): Observable<void> {
        return this.shoppingCartLinesRepository.delete(id).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ShoppingCartLineDelete'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    subtractLine(id: number): Observable<void> {
        return this.shoppingCartLinesRepository.subtract(id).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ShoppingCartLineDelete'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getShippingCost(request: EcommerceServiceShippingCostGetRequestUIModel): Observable<number> {
        return this.shippingCostRepository.getShippingCost(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ShippingCosts'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getItemsCount(): Observable<number> {
        return this.shoppingCartsRepository.getItemsCount().pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.ShoppingCart'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }
}

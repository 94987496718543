import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CustomerLiteParticularCreateRequestUIModel,
    CustomerLiteSharedUIModel,
    CustomerServiceCustomerCheckBlockedByIdResponse,
    CustomerServiceCustomerCreateProfessionalUIModel,
    CustomerServiceCustomerGetFullSingleRequestUIModel,
    CustomerServiceCustomerGetFullSingleResponseUIModel,
    CustomerServiceCustomerLogotypeUpdateRequestUIModel,
    CustomerServiceCustomerRepository,
    CustomerServiceCustomerSimpleResponseUIModel,
    CustomerServiceCustomerUpdateRequestValidationCreateRequestUIModel,
    CustomerServiceCustomerUpdateRequestValidationRepository,
    ErrorUIModel,
} from '@socialonce/enganchesaragon-adminnav-lib';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastsService } from '../toasts/toast.service';

@Injectable({
    providedIn: 'root',
})
export class CustomersService {
    constructor(
        private customerRepository: CustomerServiceCustomerRepository,
        private customerUpdateRequestValidation: CustomerServiceCustomerUpdateRequestValidationRepository,
        private toastsService: ToastsService,
        private translateService: TranslateService
    ) {}

    get(
        request: CustomerServiceCustomerGetFullSingleRequestUIModel
    ): Observable<CustomerServiceCustomerGetFullSingleResponseUIModel> {
        return this.customerRepository.getFullSingle(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getFullCustomer(): Observable<CustomerLiteSharedUIModel> {
        return this.customerRepository.getFullCustomer().pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    createParticular(request: CustomerLiteParticularCreateRequestUIModel): Observable<number> {
        return this.customerRepository.createParticular(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.CreateCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    // createProfessional(request: CustomerLiteProfessionalCreateRequestUIModel): Observable<number> {
    //     return this.customerRepository.createProfessional(request).pipe(
    //         catchError((err: unknown) => {
    //             this.toastsService.show({
    //                 time: 5000,
    //                 msg: this.translateService.instant('Errors.CreateCustomer'),
    //                 type: 'error',
    //             });
    //             return throwError(err);
    //         })
    //     );
    // }

    createProfessional(
        request: CustomerServiceCustomerCreateProfessionalUIModel
    ): Observable<number> {
        return this.customerRepository.createProfessional(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.CreateCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    updateEcommerceCustomer(id: number, request: CustomerLiteSharedUIModel): Observable<void> {
        return this.customerRepository.updateEcommerceCustomer(id, request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.UpdateCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    updateEcommerceCustomerWithValidation(
        request: CustomerServiceCustomerUpdateRequestValidationCreateRequestUIModel
    ): Observable<number> {
        return this.customerUpdateRequestValidation.createValidationRequest(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.UpdateUser'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    getSimpleCustomer(): Observable<CustomerServiceCustomerSimpleResponseUIModel> {
        return this.customerRepository.getSimpleCustomer().pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    updateLogotype(
        request: CustomerServiceCustomerLogotypeUpdateRequestUIModel
    ): Observable<string> {
        return this.customerRepository.updateLogotype(request).pipe(
            catchError((err: unknown) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.UpdateCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    customerExists(no: string): Observable<boolean> {
        return this.customerRepository.customerExists(no).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    customerCheckBlockedByNIF(nif: string): Observable<boolean | null> {
        return this.customerRepository.checkBlocked(nif).pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }

    customerCheckBlockedById(): Observable<CustomerServiceCustomerCheckBlockedByIdResponse> {
        return this.customerRepository.checkBlockedById().pipe(
            catchError((err: ErrorUIModel) => {
                this.toastsService.show({
                    time: 5000,
                    msg: this.translateService.instant('Errors.GetCustomer'),
                    type: 'error',
                });
                return throwError(err);
            })
        );
    }
}

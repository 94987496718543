import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanLoad,
    NavigationEnd,
    Route,
    Router,
    Routes,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RoutesService } from 'src/app/ecommerce/shared/services/routes/routes.service';
import { ProductServiceLanguageGetAllRequestUIModel } from '@socialonce/enganchesaragon-adminnav-lib';
import { LanguagesService } from '../../../ecommerce/shared/services/languages/languages.service';
import { CookieService } from 'ngx-cookie-service';

export let routesAreas: Routes = [];
export let routesClientArea: Routes = [];
export const routesComplianceReportsOld: Routes = [];
export let routesComplianceReports: Routes = [];
export let routesHitches: Routes = [];
export let routesKits: Routes = [];
export let routesOfficialServices: Routes = [];
export let routesRegister: Routes = [];
export let routesSearch: Routes = [];
export let routesShoppingCart: Routes = [];
export let routesCookies: Routes = [];

export const allRoutes: Routes[] = [];

@Injectable({
    providedIn: 'root',
})
export class EaRoutesGuard implements CanLoad {
    constructor(
        private routesService: RoutesService,
        private languagesService: LanguagesService,
        private cookieService: CookieService,
        private router: Router
    ) {}

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const ars: ActivatedRouteSnapshot = new ActivatedRouteSnapshot();
        ars.url = segments;
        return new Promise(resolve => {
            const request: ProductServiceLanguageGetAllRequestUIModel = {};
            this.languagesService.getAll(request).subscribe(languages => {
                if (languages.data.find(lang => lang.codeIso === ars.url[0].path)) {
                    void Promise.all([
                        this.routesService.getRoutesFile('areas', ars.url[0].path).then(routes => {
                            if (routes) routesAreas = routes;
                        }),
                        this.routesService
                            .getRoutesFile('client-area', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesClientArea = routes;
                            }),
                        // this.routesService
                        //     .getRoutesFile('compliance-reports-old', ars.url[0].path)
                        //     .then(routes => {
                        //         if (routes) routesComplianceReportsOld = routes;
                        //     }),
                        this.routesService
                            .getRoutesFile('compliance-reports', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesComplianceReports = routes;
                            }),
                        this.routesService
                            .getRoutesFile('hitches', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesHitches = routes;
                            }),
                        this.routesService.getRoutesFile('kits', ars.url[0].path).then(routes => {
                            if (routes) routesKits = routes;
                        }),
                        this.routesService
                            .getRoutesFile('official-services', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesOfficialServices = routes;
                            }),
                        this.routesService
                            .getRoutesFile('register', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesRegister = routes;
                            }),
                        this.routesService.getRoutesFile('search', ars.url[0].path).then(routes => {
                            if (routes) routesSearch = routes;
                        }),
                        this.routesService
                            .getRoutesFile('shopping-cart', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesShoppingCart = routes;
                            }),
                        this.routesService
                            .getRoutesFile('cookies', ars.url[0].path)
                            .then(routes => {
                                if (routes) routesCookies = routes;
                            }),
                    ]).then(() => {
                        allRoutes.push(
                            routesAreas,
                            routesClientArea,
                            routesComplianceReportsOld,
                            routesComplianceReports,
                            routesHitches,
                            routesKits,
                            routesOfficialServices,
                            routesRegister,
                            routesSearch,
                            routesShoppingCart
                        );
                        resolve(true);
                    });
                } else {
                    void this.router.navigateByUrl('/es/404');
                }
            });
        });
    }
}
